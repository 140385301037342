/* Font Family */
/* Scss Document */
/* Box Shadow */
/* Transition */
/* Transform Rotate */
/* Width Calc */
/*  Opacity Color */
/* Background Opacity Color */
/* Border Color */
/* Animation Key Frame */
/* translate3d */
/* translate */
/* Background Image */
/* text shadow */
html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}


.mr-15{ margin-right: 15px;}

/* body {
  background: url("../images/NewActivityImages/background.jpg") fixed;
  background-size: 100% 100%;
} */

.container-fluid {
  max-width: 80%;
}
.backevry{
  background-image: "../../images/NewActivityImages/background.jpg";
  background-size: cover;
  background-position: center;
}

.btn-outline {
  border: none;
  box-shadow: 1px 2px 2px #242424c9;
  font-size: 16px;
  padding: 0px 5px;
  height: 20px;
  float: right;
  line-height: 20px;
  margin-right: 5px;
}

header .navbar {
  background: #011c2d;
}

header .navbar .navbar-brand {
  color: #d6f0fd !important;
}

header .navbar .nav-right .burg-menu {
  font-size: 30px;
  color: #d6f0fd;
  vertical-align: middle;
  margin-left: 30px;
}

header .navbar .nav-right button {
  border-radius: 50px;
  padding: 5px 15px;
  background: #d6f0fd;
  color: #000;
  font-size: 16px;
  position: relative;
  z-index: 9999 !important;
  width: 138px;
}

header .navbar .nav-right .dropdown-menu.show {
  background: #ebf8ff;
  margin-top: -20px;
  border-radius: 20px;
  width: 80px !important;
  padding: 30px 15px 15px 15px;
  font-size: 12px;
}

header .navbar .nav-right .dropdown-menu.show a {
  color: #011c2d;
  text-decoration: none;
}

header .navbar .nav-right .dropdown-menu.show a:hover {
  opacity: 0.7;
}

footer {
  background: #d9d9d9;
  padding: 30px 0;
}

footer .footer-logo {
  width: 65px;
  filter: brightness(0) invert(1);
  margin-bottom: 40px;
}

footer .copyright p {
  margin: 0;
  color: #687b96;
}

footer .social-media {
  display: flex;
  justify-content: end;
}

footer .social-media li {
  list-style-type: none;
  margin-right: 10px;
}

footer .social-media li a {
  color: #4643d3;
  font-size: 20px;
}

footer .social-media li a:hover {
  opacity: 0.8;
}

html {
  scroll-behavior: smooth;
}

main {
  padding: 30px 0;
}

.design-vew {
  background: url("../images/NewActivityImages/mobile3.jpg");
  width: 100%;
  max-width: 257px;
  height: 525px;
  background-size: 100%;
  padding: 50px 20px;
}

.design-vew2 {
  background: url("../images/NewActivityImages/mobileoverlay.png");
  width: 100%;
  max-width: 257px;
  height: 525px;
  background-size: 100%;
  padding: 50px 20px;
  color: wheat;
}

.nav-controls {
  background: #011c2d;
  border-radius: 20px;
  margin-top: 10px;
  max-width: 257px;
  padding: 10px;
  display: flex;
}

.nav-controls a img {
  max-height: 40px;
}

.nav-controls2 {
  background: #011c2d;
  border-radius: 20px;
  margin-top: 10px;
  max-width: 257px;
  padding: 10px;
}

.box {
  background: transparent;
  border: 1px solid #011c2d;
}

.box .box-head {
  background: #011c2d;
  padding: 5px;
  color: #fff;
}
.box .box-head iconify-icon{ margin-top: 5px;}
.box .box-head h2 {
  font-size: 16px;
  text-transform: uppercase;
  color: #d6f0fd;
  margin-bottom: 0;
  display: inline-block;
}

.box .box-head .btn {
  float: right;
  background: #d6f0fd;
  padding: 0 10px;
  border-radius: 20px;
  font-size: 13px;
  text-transform: uppercase;
  margin-left: 5px;
  color: #666;
}

.box .box-head .btn:hover {
  background: #bee2f4;
}

.box .box-head .yellow-btn {
  /* background: #ffb546; */
  border-radius: 50px;
  height: 20px;
  width: 20px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  text-align: center;
}

.box .box-content {
  /* background: #fff; */
  height: 78vh;
}

.box .box-content .nav-tabs .nav-item {
  width: 48%;
  margin-bottom: 5px;
  position: relative;
  margin-right: 2%;
}

.box .box-content .nav-tabs .nav-item a {
  padding: 0.1rem 1rem;
  border: none;
  font-size: 14px;
  color: #011c2d;
  font-family: "Fira Code", monospace;
  border-radius: 0;
}

.box .box-content .nav-tabs .nav-item a::after {
  position: absolute;
  width: 10px;
  height: 24px;
  left: 0;
  top: 0;
  content: "";
  background: #7ac5ea;
}

.box .box-content .nav-tabs .nav-item a.active {
  background: #7ac5ea;
}

.box .box-content .nav-tabs .nav-item:nth-child(2) a::after {
  background: #76c454;
}

.box .box-content .nav-tabs .nav-item:nth-child(2) a.active {
  background: #76c454;
}

.box .box-content .nav-tabs .nav-item:nth-child(3) a::after {
  background: #ffb546;
}

.box .box-content .nav-tabs .nav-item:nth-child(3) a.active {
  background: #ffb546;
}

.box .box-content .nav-tabs .nav-item:nth-child(4) a::after {
  background: #a45bd2;
}

.box .box-content .nav-tabs .nav-item:nth-child(4) a.active {
  background: #a45bd2;
}

.show-code-popp .modal-header {
  background: #011c2d;
  color: #fff;
}

.show-code-popp .modal-header .btn-close-new {
  background: #e04f5f;
  color: #fff;
  border-radius: 50px;
  opacity: 100;
  padding: 0 5px;
  border: none;
  width: 30px;
  height: 30px;
  font-size: 20px;
}

.show-code-popp .modal-header .btn-link {
  background: #d6f0fd;
  color: #000;
  border-radius: 50px;
  opacity: 100;
  padding: 0 5px;
  border: none;
  width: 30px;
  height: 30px;
  font-size: 16px;
  margin-right: 10px;
}

.show-code-popp .modal-header .btn-resize {
  background: transparent;
  border: none;
  margin-right: 10px;
}

.show-code-popp .modal-header .btn-resize img {
  height: 23px;
}

.show-code-popp .modal-header button:hover {
  opacity: 0.7;
}

.plug-board {
  border-left: 20px solid #76c454;
  width: 85%;
  margin-bottom: 10px;
  font-family: "Fira Code", monospace;
  font-size: 12px;
}

.plug-board input {
  display: inline-block;
  width: 18px;
  height: 16px;
  text-align: center;
  border-radius: 2px;
  border: none;
}

.plug-board input:focus {
  border: none;
}

.plug-board .plug-board-top {
  background: #76c454;
  min-height: 30px;
  position: relative;
  padding: 4px;
}

.plug-board .plug-board-top::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 7px;
  background: #fff;
  top: -2px;
  left: -10px;
  border-radius: 2px;
}

.plug-board .plug-board-top::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 10px;
  background: #76c454;
  bottom: -5px;
  left: 10px;
  border-radius: 2px;
}

.plug-board .plug {
  min-height: 30px;
}

.plug-board .plug-board-bottom {
  background: #76c454;
  min-height: 30px;
  width: 50%;
  position: relative;
  padding: 5px;
}

.plug-board .plug-board-bottom::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 7px;
  background: #fff;
  top: -2px;
  left: 8px;
  border-radius: 2px;
}

.plug-board .plug-board-bottom::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #76c454;
  bottom: -5px;
  left: -10px;
  border-radius: 2px;
}

.plug-board .pink-span {
  background: #674394;
  display: inline-block;
  padding: 2px 3px;
  border-radius: 5px;
}

.plug-board .yellow-span {
  background: #ffb546;
  display: inline-block;
  padding: 2px 3px;
  border-radius: 5px;
}

.pin {
  background: #f0c827;
  min-height: 40px;
  position: relative;
  padding: 5px 10px 5px 25px;
  font-family: "Fira Code", monospace;
  font-size: 12px;
  width: 85%;

  margin-bottom: 1px;
}

.pin input {
  display: inline-block;
  width: 18px;
  height: 16px;
  text-align: center;
  border-radius: 2px;
  border: none;
}

.pin input:focus {
  border: none;
}

.pin::before {
  content: "";
  position: absolute;
  width: 22px;
  height: 11px;
  background: #fff;
  top: -3px;
  left: 8px;
  border-radius: 0 0 2px 2px;
 
}

/* .pin::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 10px;
  background: #0b1de6;
  bottom: -5px;
  left: 10px;
  border-radius: 2px;
} */

.work-space {
  border-left: 10px solid #7ac5ea;
  width: 85%;
  margin-bottom: 2px;
  font-family: "Fira Code", monospace;
  font-size: 12px;
  position: relative;
  z-index: 1;
}

.work-space .plug-board-top {
  background: #7ac5ea;
  min-height: 30px;
  position: relative;
  padding: 4px;
  border-bottom: 10px solid #011c2d;
}

.work-space .plug-board-top .function {
  background: #011c2d;
  width: 116px;
  height: 23px;
  color: #7ac5ea;
  padding: 3px;
  text-align: center;
  float: right;
  margin-right: -4px;
}

.work-space .plug-board-top::before {
  content: "";
  position: absolute;
  width: 18.5px;
  height: 7px;
  background: #fff;
  top: -2px;
  left: -4px;
  border-radius: 2px;
}

.work-space .plug-board-top::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 10px;
  background: #011c2d;
  bottom: -15px;
  left: 25px;
  border-radius: 2px;
  z-index: 1;
}

.work-space .plug {
  min-height: 30px;
}

.work-space .plug .plug-blue {
  border-left: 15px solid #011c2d;
  min-height: 95px;
  height: auto;
}

.work-space .plug-board-bottom {
  background: #7ac5ea;
  min-height: 30px;
  width: 60%;
  position: relative;
  padding: 0;
}

.work-space .plug-board-bottom::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 7px;
  background: #fff;
  top: -2px;
  left: 25px;
  border-radius: 2px;
}

.work-space .plug-board-bottom::after {
  content: "";
  position: absolute;
  width: 17px;
  height: 10px;
  background: #7ac5ea;
  bottom: -5px;
  left: 2px;
  border-radius: 2px;
}

.work-space .plug-board-bottom .bottom-blue {
  background: #011c2d;
  width: 150px;
  height: 20px;
}

.pin2 {
  background: #76c454;
  min-height: 30px;
  position: relative;
  padding: 5px 10px 5px 25px;
  font-family: "Fira Code", monospace;
  font-size: 12px;
  width: 85%;
}

.pin2 input {
  display: inline-block;
  width: 18px;
  height: 16px;
  text-align: center;
  border-radius: 2px;
  border: none;
}

.pin2 input:focus {
  border: none;
}

.pin2::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 7px;
  background: #fff;
  top: -2px;
  left: 11px;
  border-radius: 2px;
}

.pin2::after {
  content: "";
  position: absolute;
  width: 17px;
  height: 10px;
  background: #76c454;
  bottom: -5px;
  left: 10px;
  border-radius: 2px;
}

.choose-code-design {
  max-width: 250px;
  margin-bottom: 20px;
}

.choose-code-design .btn-outline-primary {
  color: #d6f0fd;
  background-color: #001325;
  border-color: #001325;
}

.choose-code-design .btn-check:active + .btn-outline-primary,
.choose-code-design .btn-check:checked + .btn-outline-primary,
.choose-code-design .btn-outline-primary.active,
.choose-code-design .btn-outline-primary.dropdown-toggle.show,
.choose-code-design .btn-outline-primary:active {
  color: #011c2d;
  background-color: #d6f0fd;
  border-color: #001325;
}

.choose-code-design .btn-check:active + .btn-outline-primary:focus,
.choose-code-design .btn-check:checked + .btn-outline-primary:focus,
.choose-code-design .btn-outline-primary.active:focus,
.choose-code-design .btn-outline-primary.dropdown-toggle.show:focus,
.choose-code-design .btn-outline-primary:active:focus {
  box-shadow: none;
}

.choose-code-design .btn-outline-primary:focus {
  box-shadow: none;
}

.maths-box {
  background: #ffb546;
  min-height: 30px;
  position: relative;
  padding: 8px;
  font-family: "Fira Code", monospace;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 5px;
}

.maths-box input {
  display: inline-block;
  width: 18px;
  height: 16px;
  text-align: center;
  border-radius: 2px;
  border: none;
}

.maths-box input:focus {
  border: none;
}

.variable-pin {
  background: #76c454;
  min-height: 30px;
  position: relative;
  padding: 5px 10px 5px 25px;
  font-family: "Fira Code", monospace;
  font-size: 12px;
  width: 85%;
}

.variable-pin input {
  display: inline-block;
  width: 18px;
  height: 16px;
  text-align: center;
  border-radius: 2px;
  border: none;
}

.variable-pin input:focus {
  border: none;
}

.variable-pin::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 7px;
  background: #fff;
  top: -2px;
  left: 11px;
  border-radius: 2px;
}

.variable-pin::after {
  content: "";
  position: absolute;
  width: 17px;
  height: 10px;
  background: #76c454;
  bottom: -5px;
  left: 10px;
  border-radius: 2px;
}

.btmob {
  background: linear-gradient(to right, #ee0979, white);
  height: 50%;
  width: 90%;
  font-style: italic;
}

.pic {
  height: 350px;
  width: 200px;
}

.btmob:hover {
  background: linear-gradient(to right, #5ff53a, white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* changed */
.mr-2 {
  margin-right: 70rem;
}

.mr-5 {
  margin-right: 250rem;
}

/* .mr-3 {
  margin-right: 1000rem ;
  padding-right: 10rem;
} */

.p-3 {
  padding: 1rem;
}

.w175 {
  width: 180px !important;
}

/* changed */
.mt-8 {
  margin-top: 10rem;
}

.fontq {
  font-style: oblique;
}

.mt-1 {
  margin: 4rem !important;
}

.rm {
  right: 2rem;
}

.move-left {
  width: 100px;
  height: 60px;
  transition: margin-left 0.5s ease;
  /* CSS transition for smooth movement */
}

.btmob2 {
  background: linear-gradient(to right, #c7fafb, white);
  height: 70%;
  width: 60%;
  font-style: italic;
}

.btmob2:hover {
  background: linear-gradient(to right, #edf416, #f6f7ee);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.runbttn {
  background: #001325;
  font-size: 18px;
  color: white;
  text-align: center !important;
}

/* CSS */
.button-86 {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-86::after,
.button-86::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

.button-86::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-86:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-86:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-86:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

.me-1 {
  margin-top: -20px;
}

.button-gap {
  margin-top: 20px;
}

.button-gap-t {
  margin-bottom: -100px;
}

.button-gap-2 {
  margin-top: -20pt !important;
}

.font-1 {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: red;
  font-size: xx-large;
}

.button-81 {
  height: 30%;
  width: 90px !important;
  font-size: 14px !important;
  display: inline-block !important;
  margin-right: 10px !important;
  border-radius: 30px;
  margin-top: 120px !important;
}

.button-81:hover {
  background-color: #1e293b;
  color: #fff;
}

@media (min-width: 768px) {
  .button-81 {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}

.questionfontsize {
  font-size: x-large;
  font-weight: 700;
  color: red;
  font-family: aclonica;
}

/* Arcade-style text */
.arcade-text {
  font-family: "Press Start 2P", sans-serif;
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #800080;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.arcade-text2 {
  font-family: "Press Start 2P", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: rgb(208, 8, 8);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.col-9 {
  width: 75%;
  background-color: #ffffff;
  /* Background color for the right div */
  display: flex;
  flex-direction: column;
  /* border: 2px solid rgb(139, 102, 192); */
}

/* Make the right pane match the height of the left pane */
.col-md-3,
.col-9 {
  align-self: stretch;
}

.header {
  background-color: black;
  color: white;
  font-weight: bold;
  border-left: 10px solid #000000;
  margin: 0;
  font-size: 15px;
}

.rightheading {
  font-weight: bold;
  color: rgb(139, 102, 192);
  padding: 1%;
}

.rightpaneul {
  font-weight: bold;
  color: rgb(32, 4, 34);
  padding: 1%;
}

.custom-row {
  --bs-gutter-x: 0 !important;
  /* Set gutter width to 0, !important ensures it overrides Bootstrap */
}

.design-vew2 {
  background: url("../images/NewActivityImages/mobileoverlay.png");
  width: 100%;
  max-width: 257px;
  height: 525px;
  background-size: 100%;
  padding: 50px 20px;
}

.design-vew22 {
  background: url("../images/NewActivityImages/mobile.png");
  width: 100%;
  max-width: 257px;
  height: 525px;
  background-size: 100%;
  padding: 50px 20px;
  margin: 0 auto;
}

.design-vew7 {
  background: url("../images/NewActivityImages/ZomboDroid_10102023010333.jpg");
  width: 100%;
  max-width: 257px;
  height: 525px;
  background-size: 100%;
  padding: 50px 20px;
  margin: 20px auto 0 auto;

}

.mobile-phone {
  width: 80%;
  /* Adjust the width as needed */
  height: 450px;
  /* Adjust the height as needed */
  background-color: black;
  border: 2px solid gray;
  border-radius: 20px;
  /* Optional: Add rounded corners */
  color: wheat;
  margin-top: 20px;
}

.sucesstext {
  font-size: l;
  color: #d6f0fd;
}

.Textsiz {
  font-size: larger;
}

.md--10 {
  margin-bottom: 3px !important;
}

.whiteboard {
  top: 20px;
  /* Adjust the top value to control the vertical position */
  right: 20px;
  /* Adjust the right value to control the horizontal position */
  width: 800px;
  /* Adjust the width of the whiteboard as needed */
  height: 700px;
  /* Adjust the height of the whiteboard as needed */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Optional: Add a shadow for depth */
  z-index: 999;
  /* Optional: Adjust the z-index to control the stacking order */
}

.container {
  display: flex;
  flex-direction: column;
}

.react-calenderdate {
  background-color: #76c454;
}

.Acitivityon {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: xx-large;
  color: black;
  background: #e7f194;
  text-align: center;
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
  /* Vertical centering */
  height: 50vh;
}

/* Dropdown container */
#languageDropdownContainer {
  display: inline-block;
  margin: 10px;
}

/* Label styling */
label {
  font-weight: bold;
  margin-right: 10px;
}

/* Dropdown select element */
#languageDropdown {
  padding: 8px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
}

/* Dropdown options */
#languageDropdown option {
  font-size: 15px;
}

/* Selected option style */
#languageDropdown option:checked {
  background-color: #007bff;
  color: #fff;
}

/* Selected option style on hover */
#languageDropdown option:checked:hover {
  background-color: #0056b3;
}

/* Style the container when an option is selected */
#languageDropdownContainer p {
  margin-top: 10px;
  font-weight: bold;
  color: #007bff;
}

/* Styles for a small box */
.small-box {
  width: 150px;
  /* Adjust the width as needed */
  height: 100px !important;
  /* Adjust the height as needed */
  background: linear-gradient(
    90deg,
    rgba(255, 250, 250, 1) 0%,
    rgba(78, 9, 121, 1) 35%,
    rgba(142, 255, 0, 1) 100%
  );
  border: 2px
    linear-gradient(
      90deg,
      rgba(255, 250, 250, 1) 0%,
      rgba(78, 9, 121, 1) 35%,
      rgba(142, 255, 0, 1) 100%
    );
  /* Border color and thickness */
  border-radius: 20px;
  /* Border radius for rounded corners */
  color: #ffffff;
  /* Text color */
  text-align: center;
  /* Center text horizontally */
  line-height: 100px;
  /* Center text vertically */
  font-size: 35px;
  /* Font size */
  margin-left: 2rem !important;
  margin-top: 30px;
}

/* Hover effect */
.small-box:hover {
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
}

.HeaderComments {
  font-size: medium !important;
  color: black;
}

.small-box1 {
  /* background: url("../images/NewActivityImages/20492950.jpg"); */
  border-radius: 20px;
  /* Border radius for rounded corners */
  color: white;
  /* Text color */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  /* Center text horizontally */
  line-height: 100px;
  /* Center text vertically */
  font-size: 20px;
  /* Font size */
  margin-left: 0rem !important;
  margin-top: 20px !important;
  text-align: center !important;
}

.inputFieldstyle {
  margin-top: 5rem;
  width: 80px;
}

.boxNine {
  width: 200px;
  /* Set the width of the box */
  height: 100px;
  /* Set the height of the box */
  background-color: #d39cae;
  /* Set the background color */
  color: #fff;
  /* Set the text color */
  padding: 20px;
  /* Add padding inside the box */
  border: 1px solid #ccc;
  /* Add a border with a color */
  border-radius: 10px;
  /* Add rounded corners */
  text-align: center;
  /* Center the text horizontally */
}

.boxSandK {
  width: 80px;
  height: 40px;
  background-color: #ebe1a2;
  text-align: center;
}

.boxSandK:hover {
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
}

@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:200");

/* body  {
    background-image: url('https://static.pexels.com/photos/414171/pexels-photo-414171.jpeg');
  background-size:cover;
        -webkit-animation: slidein 100s;
        animation: slidein 100s;

        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;

        -webkit-animation-direction: alternate;
        animation-direction: alternate;              
} */

@-webkit-keyframes slidein {
  from {
    background-position: top;
    background-size: 3000px;
  }

  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}

@keyframes slidein {
  from {
    background-position: top;
    background-size: 3000px;
  }

  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(75, 75, 250, 0.3);
  border-radius: 3px;
}

.center h1 {
  text-align: center;
  color: white;
  font-family: "Source Code Pro", monospace;
  text-transform: uppercase;
}

.design-vew4 {
  background: url("../images/NewActivityImages/bg7.jpg");
  width: 100%;
  max-width: 257px;
  height: 525px;
  background-size: 100%;
  padding: 50px 20px;
  border-radius: 3rem;
}
.checkInput {
  border-color: #ff9f43;
  background: #fff;
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 10px;
  width: 40%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
  height: 40px;
}

.dropdownoutfit {
  text-transform: uppercase;
  background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
  -webkit-background-clip: text;
  color: black;
}

.row {
  display: flex;
}

.Toolboxmaxwidth {
  max-width: 400px;
}

.Workspacemaxwidth {
  min-width: 600px;
}
.Workspacemaxwidth1 {
  min-width: 400px;
}

.col-md-3 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  box-sizing: border-box;
  padding: 0 15px;
  border: 1px solid #ccc;
  /* Add border style here */
}

/* Additional styling for the content within the columns (optional) */
.col-md-3 div {
  padding: 10px;
  /* Add padding to the div content (adjust as needed) */
}

.mt-40 {
  margin-top: 20rem !important;
}

.mt-30 {
  margin-top: 15rem !important;
}
.mt-26 {
  margin-top: 1rem !important;
}
.mt-60 {
  margin-top: 28rem !important;
  margin-left: 7rem;
}
.mt-50 {
  margin-top: 27.8rem;
  margin-left: -0.2rem;
}

.buttonsession {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

/* .buttonsession:hover{
  background-color: red;
} */
.buttonsession1 {
  height: 70px;
  width: 90px;
  /* border: 1px solid #007bff; */
  background-color: black;
  color: black;
  font-size: small;
}

/* .buttonsession1:hover{
  background-color:blue;
} */
.toolboxcss {
  font-size: xx-large;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #2b2b2a;
}

.container33 {
  display: inline-block;
  background-color: white;
  padding: 5px 10px;
  border: 1px solid #000;
  border-radius: 5px;
  height: 30px;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 30px;
  width: 140px;
  text-align: start;
}

.insbox {
  height: 60px;
  width: 60px;
  background-color: #007bff;
}

.NavbarApp {
  height: 35px;
  /* width: 800px; */
  background-color: #011c2d;
  color: #d6f0fd;
  padding: 3px;
  vertical-align: middle;
}

.ybox {
  background-color: rgb(231, 211, 32);
  height: 300px;
  width: 2000px;
}

.iconify-icon-right {
  float: right;
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
}

.iconify-icon-left {
  float: left;
  display: flex;
  justify-content: center;
  /* Horizontal centering */
  align-items: center;
}

.setpropertybox {
  width: 400px;
  height: 40px;
  background-color: rgb(247, 232, 66);
  font-size: medium;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.auto-width-box {
  display: inline-block;
  padding: 10px;
  border: 1px solid rgb(247, 232, 66);
  white-space: nowrap;
  /* Prevent text from wrapping */
  min-width: 10px;
  /* Set a minimum width for the box */
  max-height: 40px;
  background-color: white;
}

/* Basic button styles */
.greenifybutton {
  bottom: 10px;
  display: inline-block;
  padding: 10px 20px;
  /* Adjust padding to control button size */
  font-size: 16px;
  /* Adjust font size */
  background-color: #a0f871;
  /* Button background color */
  color: #fff;
  /* Text color */
  border: none;
  /* Remove the default button border */
  border-radius: 20px;
  /* Add rounded corners */
  cursor: pointer;
  /* Show pointer cursor on hover */
  width: 240px;
  height: 80px;
}

/* Button hover effect */
.greenifybutton:hover {
  background-color: #0056b3;
  /* Change background color on hover */
}

/* Button focus effect (keyboard navigation) */
.greenifybutton:focus {
  outline: none;
  /* Remove the default focus outline */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  /* Add a subtle shadow on focus */
}

/* Button active effect (when clicked) */
.greenifybutton:active {
  background-color: #004499;
  /* Change background color when clicked */
}

.buttoncolour {
  height: 100px;
  width: 40px;
}

.margintop {
  margin-top: 70px !important;
}
.design-vew7box {
  width: 200px;
  height: 170px;
  background-color: #bdddf1;
  border: 2px solid #2980b9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  padding-top: 25px;
  color: #000000;
  font-size: 18px;
  line-height: 1.5;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-top: 210px;
  margin-left: 10px;
}

select {
  max-width: 150px;
  text-overflow: ellipsis;
}

.selectinpin {
  background-color: #ffffff;
  border-color: #ffffff;
  outline: none;
  font-family: "Courier New", monospace;
  font-size: 10px;
  width: 23%;
  font-weight: normal;
  color: #000000;
  font-style: normal;
  border-radius: 5px;
  line-height: 6px;
  margin: 3px;
}
.controlhead {
  background-color: #fff176;
  font-weight: 400;
  color: #000;
  font-family: "Gotham 4r", sans-serif, sans-serif;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  border-spacing: 4px;
  font-size: 14px;
  font-style: normal;
  border-spacing: 4px;
  border-bottom: 2px solid #000;
}

/* CSS */
.button-12 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  border-radius: 20px;
  border: none;
  width: 250px;
  margin: 0 auto;

  background: #2b2b2a;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.1),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.5),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.12);
  color: #dfdedf;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-12:focus {
  box-shadow: inset 0px 0.8px 0px -0.25px rgba(235, 229, 229, 0.2),
    0px 0.5px 1px rgba(243, 240, 240, 0.1),
    0px 0px 0px 3.5px rgba(243, 245, 249, 0.5);
  outline: 0;
}

.plug-boardwork-space-height {
  height: 80px;
}
.buttonmarginleft-3 {
  margin-left: 0.7rem;
}
.buttonmarginleft-7 {
  margin-left: 1.7rem;
}
.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}
.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}
.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(237, 229, 229);
  padding: 20px;
  border: 1px solid #ccc;
}

.popup.open {
  display: block;
}
/* Add this CSS to your existing stylesheet or create a new one */

/* Define styles for filled star (★) */
.star-filled {
  color: gold; /* Change the color to your desired filled color */
}

/* Define styles for empty star (☆) */
.star-empty {
  color: gray; /* Change the color to your desired empty color */
}

/* Define styles for hover effect */
.star:hover {
  transform: scale(1.2); /* Increase the size of the star on hover */
  transition: transform 0.2s ease; /* Add smooth transition effect */
}

.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  background-image: linear-gradient(#309f2e, #68f095);
  color: white;
  border: solid 2px #ffffff;
  height: 50px;
  padding: 0px 20px;
  border-radius: 5px;
  font-weight: 600;
  transform: scale(0.89);
  position: relative;
}
.button:not(:hover) .hide,
.button:not(:hover) .icon::before,
.button:not(:hover) .icon::after {
  opacity: 0;
  visibility: hidden;
  transform: scale(1.4);
}
.hide {
  transition: all 0.2s ease;
}
.button:active {
  background-image: linear-gradient(#313ed7, #3470fa);
  border-color: #313ed7;
}
.icon {
  position: relative;
}
.icon::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 6px;
  transform: translate(-50%, -50%);
  background-color:  #652c8b;
  border-radius: 100%;
}
.icon::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(-19%, -60%);
  width: 100px;
  height: 33px;
  background-color: transparent;
  border-radius: 12px 22px 2px 2px;
  border-right: solid 2px  #652c8b;
  border-top: solid 2px transparent;
}
.icon .text-icon {
  color:  #652c8b;
  position: absolute;
  font-size: 12px;
  left: -37px;
  top: -38px;
}
.icon svg {
  width: 20px;
  height: 20px;
  border: solid 2px transparent;
  display: flex;
}
.button:hover .icon svg {
  border: solid 2px  #652c8b;
}
.padding-left {
  position: absolute;
  width: 20px;
  height: 2px;
  background-color: #652c8b;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.padding-left:before {
  content: "";
  width: 2px;
  height: 10px;
  background-color:  #652c8b;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.padding-left:after {
  content: "";
  width: 2px;
  height: 10px;
  background-color:  #652c8b;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.padding-left-line {
  position: absolute;
  width: 30px;
  height: 2px;
  background-color:  #652c8b;
  left: -24px;
  top: 11px;
  transform: rotate(-50deg);
}
.padding-left-line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 6px;
  transform: translate(-50%, -50%);
  background-color:  #652c8b;
  border-radius: 100%;
}
.padding-left-text {
  color:  #652c8b;
  font-size: 12px;
  position: absolute;
  white-space: nowrap;
  transform: rotate(50deg);
  bottom: 30px;
  left: -67px;
}

.padding-right {
  position: absolute;
  width: 20px;
  height: 2px;
  background-color:  #652c8b;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.padding-right:before {
  content: "";
  width: 2px;
  height: 10px;
  background-color:  #652c8b;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.padding-right:after {
  content: "";
  width: 2px;
  height: 10px;
  background-color:  #652c8b;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.padding-right-line {
  position: absolute;
  width: 30px;
  height: 2px;
  background-color:  #652c8b;
  right: -24px;
  top: 11px;
  transform: rotate(50deg);
}
.padding-right-line::before {
  content: "";
  position: absolute;
  left: 30px;
  top: 0;
  width: 6px;
  height: 6px;
  transform: translate(-50%, -50%);
  background-color:  #652c8b;
  border-radius: 100%;
}
.padding-right-text {
  color:  #652c8b;
  font-size: 12px;
  position: absolute;
  white-space: nowrap;
  transform: rotate(-50deg);
  bottom: 34px;
  left: 21px;
}
.background {
  position: absolute;
}
.background::before {
  content: "";
  position: absolute;
  right: 27px;
  bottom: -70px;
  width: 100px;
  height: 53px;
  background-color: transparent;
  border-radius: 0px 0px 22px 22px;
  border-right: solid 2px  #652c8b;
  border-bottom: solid 2px transparent;
}
.background::after {
  content: "";
  position: absolute;
  right: 25px;
  bottom: -20px;
  width: 6px;
  height: 6px;
  background-color:  #652c8b;
  border-radius: 100%;
}
.background-text {
  position: absolute;
  color:  #652c8b;
  font-size: 12px;
  bottom: -70px;
  left: -115px;
}
.border {
  position: absolute;
  right: 0;
  top: 0;
}
.border:before {
  content: "";
  width: 15px;
  height: 15px;
  border: solid 2px  #652c8b;
  position: absolute;
  right: 0%;
  top: 0;
  transform: translate(50%, -50%);
  border-radius: 100%;
}
.border:after {
  content: "";
  width: 2px;
  height: 25px;
  background-color:  #652c8b;
  position: absolute;
  right: -10px;
  top: -15px;
  transform: translate(50%, -50%) rotate(60deg);
}
.border .border-text {
  position: absolute;
  color:  #652c8b;
  font-size: 12px;
  right: -112px;
  top: -30px;
  white-space: nowrap;
}


.input-container {
  margin-top: 20px;
}

.input-field {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.add-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #0056b3;
}

